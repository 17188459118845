// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Workspaces/WorkspaceContext/WorkspaceContext.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Workspaces/WorkspaceContext/WorkspaceContext.tsx");
  import.meta.hot.lastModified = "1728334464000";
}
// REMIX HMR END

import { createContext, useContext, useMemo } from 'react';
import { ENTITY_TYPE } from '~/lib/constants';
const WorkspaceContext = createContext({
  workspace: null,
  workspaceUserRole: null,
  workspaceModules: [],
  entitiesList: [],
  workspacesList: [],
  reportTemplate: [],
  verticalList: []
});
export const WorkspaceProvider = ({
  children,
  myWorkspace,
  myWorkspaceList = []
}) => {
  _s();
  const value = useMemo(() => {
    const entitiesList = myWorkspace?.entityIds || [];
    const workspace = myWorkspace?.workspaceId;
    const reportTemplate = myWorkspace?.reportTemplateIds?.reportTemplateIds;
    const workspaceUserRole = myWorkspace?.workspaceUserRoleId;
    const workspaceModules = workspace?.workspaceModuleIds?.map(item => item?.code) || [];
    const verticalList = myWorkspace.workspaceVerticals || [];
    const workspacesList = myWorkspaceList?.map(item => ({
      name: item?.name,
      code: item?.appId,
      type: ENTITY_TYPE.Workspace,
      id: item?.id
    })) || [];
    return {
      workspace,
      workspaceUserRole,
      workspaceModules,
      entitiesList,
      workspacesList,
      reportTemplate,
      verticalList
    };
  }, [myWorkspace, myWorkspaceList]);
  return <WorkspaceContext.Provider value={value}>
      {children}
    </WorkspaceContext.Provider>;
};
_s(WorkspaceProvider, "tPauEVZ6EeuERV9ttvKTwQ7++Gw=");
_c = WorkspaceProvider;
export const useWorkspace = () => {
  _s2();
  const context = useContext(WorkspaceContext);
  return context;
};
_s2(useWorkspace, "b9L3QQ+jgeyIrH0NfHrJ8nn7VMU=");
var _c;
$RefreshReg$(_c, "WorkspaceProvider");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;