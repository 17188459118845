// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/RestrictedElement/RestrictedElement.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/RestrictedElement/RestrictedElement.tsx");
  import.meta.hot.lastModified = "1728334464000";
}
// REMIX HMR END

import { Fragment } from 'react';
import { useHasPermission } from '~/lib/hooks/useHasPermission';
import { NoAccessPage } from '~/components/ErrorComponent';
export const RestrictedElement = ({
  permissions,
  children,
  checkAll = false,
  additionalCheck = true,
  noAccessComponent = <Fragment />
}) => {
  _s();
  const {
    checkUserHasPermission
  } = useHasPermission();
  if (!additionalCheck) {
    return noAccessComponent;
  }
  const hasPermission = checkUserHasPermission(permissions, checkAll);
  if (!hasPermission) {
    return noAccessComponent;
  }
  return <>{children}</>;
};

// The Main Access Component Of the Application
_s(RestrictedElement, "yC8rCPuNCg7E8FUdX8xiMzc+c6s=", false, function () {
  return [useHasPermission];
});
_c = RestrictedElement;
export const AccessComponent = ({
  hasAccess,
  children,
  customErrorView = false
}) => {
  // If there is doesn't have any  access and view is setting to the
  // Custom Error View
  if (!hasAccess && customErrorView) return <NoAccessPage />;

  // If the There isn't any no access, returning Null Element
  if (!hasAccess) return <></>;

  // Rendering the Accessed Elements
  return <>{children}</>;
};
_c2 = AccessComponent;
var _c, _c2;
$RefreshReg$(_c, "RestrictedElement");
$RefreshReg$(_c2, "AccessComponent");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;